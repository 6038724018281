<template>
  <div class="auth">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">线下认证</div>
    <div class="card">
        <div class="step_lines">
            <span class="active">1</span>
            <div :class="[ 'line', step>1 ? 'active': '']"></div>
            <span :class="[ step>1 ? 'active': '']">2</span>
        </div>
        <div class="step_text">
            <span class="active">选择认证网点</span>
            <span :class="[ step>1 ? 'active': '']">认证申请成功</span>

        </div>
    </div> 
    <div class="auth-card" v-if="step == 1">
        <div class="catalog">
            <p>选择开户行所在城市<span class="key">*</span></p>
            <div class="select">
            <select v-model="defaultOfflineCity">
                <option value="">请选择</option>
                <option value="450100">南宁市</option>
                <option value="450200">柳州市</option>
                <option value="450300">桂林市</option>
                <option value="450400">梧州市</option>
                <option value="450500">北海市</option>
                <option value="450600">防城港市</option>
                <option value="450700">钦州市</option>
                <option value="450800">贵港市</option>
                <option value="450900">玉林市</option>
                <option value="451000">百色市</option>
                <option value="451100">贺州市</option>
                <option value="451200">河池市</option>
                <option value="451300">来宾市</option>
                <option value="451400">崇左市</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>选择网点行别<span class="key">*</span></p>
            <div class="select">
            <select v-model="defaultOfflineSubBankCode">
                <option value="">请选择</option>
                <option v-for="item in branchList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>选择网点<span class="key">*</span></p>
            <div class="select">
            <select v-model="offlineBankCode">
                <option value="">请选择</option>
                <option v-for="item in subBranchList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
           </select>
            </div>
        </div>
       <div class="footer-btn">
            <div class="auth-btn" @click="setStep(2)">提交申请</div>
        </div>

    </div>
    <div class="auth-card" v-if="step == 2">
        <div class="pass">
            <img src="../../assets/img/tongguo.png" alt="">
            <!-- <p class="p1">&nbsp;&nbsp;&nbsp;恭喜您！</p> -->
            <p class="p2">线下认证申请成功，请等待网点审核，点击确定即将前往首页！</p>
        </div>
        <div class="footer-btn">
            <router-link to="/" class="auth-btn">确定</router-link>
        </div>
    </div>
    <!-- <Dropdown @item-click="dropDownClick" :show="true" :itemlist="itemlist"></Dropdown> -->
    <!-- <van-popup v-model:show="dropDownBool">
        <div class="pop">
            <Dropdown @item-click="dropDownClick"></Dropdown>
        </div>        
    </van-popup> -->
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { ref,watch,reactive,toRefs } from 'vue';
import { apiGetOfflineBank,apiSaveOfflineAuth,apiBranchBanks,apiSubBranchBanks} from '../../api/axios';
import { Toast,Dialog } from 'vant';
import { useStore } from 'vuex';
export default {
   setup() {
        let router = useRouter();
        let store = useStore();
        const step = ref(1);
        const watchF=ref(false);
        const setStep = (val) =>{
            if(state.offlineBankCode!=='') {
                apiSaveOfflineAuth({offlineBankCode:state.offlineBankCode}).then(res => {                
                    if(res.code == '1'){
                        step.value = val;
                    }
                    else {
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close
                            console.log('关闭提示')
                        });
                    }
                });
            }else {
                Toast('请先选择认证网点')
            }
        };
        const state = reactive({
            defaultOfflineCity:'',
            defaultOfflineSubBankCode:'',
            offlineBankCode:'',
            branchList:[],
            subBranchList:[],
        });

        apiGetOfflineBank({}).then(res => {         
            console.log(res)       
             if(res.code == '1'){
                 state.defaultOfflineCity = res.info.defaultOfflineCity;
                 state.defaultOfflineSubBankCode = res.info.defaultOfflineSubBankCode;
                 state.offlineBankCode = res.info.defaultOfflineBankCode;
              }
              else {
                Toast(res.msg);
              }
          });

        //监听所属市变化
        watch(()=> [state.defaultOfflineCity,state.defaultOfflineSubBankCode], ([new1,new2],[old1,old2] ) => {
            if(new1 != old1) {
                if(new1 != ''){
                    apiBranchBanks({cityCode: new1}).then(res => {           
                        if(res.code === '1'){
                            state.branchList = res.info.bankList;
                            state.subBranchList.splice(0,state.subBranchList.length);
                            if(watchF.value) {
                                state.defaultOfflineSubBankCode = '';
                                state.offlineBankCode = ''; 
                            }
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                     
                }else {
                    state.branchList.splice(0,state.branchList.length)
                    state.subBranchList.splice(0,state.subBranchList.length);
                    watchF.value=true;
                    state.defaultOfflineSubBankCode = '';
                    state.offlineBankCode = '';
                }
            }
            if(new2!=old2) {
                if(new2 != '') {                 
                    apiSubBranchBanks({branchBankCode: new2}).then(res => {        
                        if(res.code === '1'){
                            state.subBranchList = res.info.subBankList;
                            if(watchF.value) {
                                state.offlineBankCode = '';
                            }else{
                                watchF.value=true;
                            }
                        }
                        else {
                            Toast(res.msg);
                        }            
                    })
                    
                }else {
                    state.subBranchList.splice(0,state.subBranchList.length);
                    watchF.value=true;
                    state.offlineBankCode = '';
                    
                }
                
            }

        })


     return {
         store,
         step,
         ...toRefs(state),
         setStep,

     }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  },

}
</script>
<style lang="less" scoped>
.auth {
    padding-top: 12.5vw;
    // background-color: #fff;
    .tips {
        text-align: center;
        font-family: cursive;
        font-size: 5vw;
        font-weight: 600;
    }
    .step_lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4vw;
        span {
            text-align: center;
            line-height: 6vw;
            width: 6vw;
            height: 6vw;
            border-radius: 50%;
            color: rgb(128, 127, 127);
            background-color: #ccc;
        }
        .line {
            border-top: 1px solid #ccc;
            flex: 1;
        }
        .active {
        color: #fff;
        background-color: #ff9900;
        border-color: #ff9900;
        }
    }
    .step_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 4vw;
        margin-top: 3px;
        color: #666;
        .active {
            color: #ff9900;
        }
    }
    
    .auth-card {
        margin: 4vw;
        background-color: #fff;
        border-radius: 1.5vw;
        padding: 4vw;
        .catalog {
            display: flex;
            align-items: center;
            
            p:first-child {
                width: 24vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                
                .key {
                    color: #ff6600;
                }
            }
            // p:last-child {
            //     width: 57vw;
            //     font-size: 4vw;
            //     padding-left: 4vw;

            // }
            .add_pic {
                width: 57vw;
                height: 39vw;
                font-size: 4vw;
                // padding-left: 4vw;
                margin-left: 4vw;
                border-radius: 1vw;
                background-color: #f4f8fe;
                text-align: center;
                img {
                    width: 13vw;
                    margin: 6vw 0 2vw;
                }
                p {
                    color: #336699;
                }

            }
            input,select {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 57vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
            ::-webkit-input-placeholder {
              color: #7f9abb;
            }
            ::-moz-placeholder {
              color: #7f9abb;
            }
            :-ms-input-placeholder {
              color: #7f9abb;
            }
            :-moz-placeholder {
              color: #7f9abb;
            }
            .input {
                display: inline-block;
                background-color: #7f9abb;
                border: 0;
                margin-left: 4vw;
                width: 57vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }

        }
        .pass {
            text-align: center;
            margin: 4vw 0;
            img {
                width: 15vw;
            }
            .p1 {
                font-weight: 600;
                font-size: 5.5vw;
            }
            .p2 {
                font-size: 4vw;
                margin-top: 2vw;
            }
        }
        .footer-btn {
            display: flex;
            justify-content: center;
            .auth-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4vw;
            padding: 1.5vw;
            border-radius: 7vw;
            border: 0;
            margin: 5vw 2vw 0;
            width: 25vw;
            text-align: center;
            letter-spacing: 1px;
        }   
    

        }
        
    }
    .pop {
        width: 80vw;
        height: 70vh;
    }
    
}
</style>
